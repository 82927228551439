<template>
  <FloatingUi ref="floating" :reduce-height="design.reduceHeight" @hidden="hidden">
    <div
      v-if="$slots.trigger"
      :class="[{'active' : isOpen}]"
    >
      <slot name="trigger" />
    </div>
    <template #content="props">
      <div class="overflow-hidden flex flex-col">
        <div v-if="$slots.head" :class="[ {'py-4 px-7 w-[628px]' : !design.customDesignHead}, design.customDesignHead ]">
          <div class="relative">
            <div class="absolute top-0 right-0">
              <UiButton color="light" class="text-lg aspect-[1/1] leading-[0] w-[37px]" @click="close">
                x
              </UiButton>
            </div>
            <slot name="head" />
          </div>
        </div>
        <div v-if="$slots.default" :class="[ {'py-4 px-7 w-[628px] overflow-y-auto' : !design.customDesign}, design.customDesign ]">
          <slot v-bind="props" />
        </div>
        <div
          v-if="$slots.foot"
          :class="[{'border-t border-neutral-200 grid grid-cols-2 gap-2 content-center py-4 px-5 text-left' : !design.customDesignFooter }, design.customDesignFooter ]"
        >
          <slot name="foot" />
        </div>
        <div v-if="$slots.footerClose" class="flex justify-end pb-4 px-7">
          <button @click="close">
            <slot name="footerClose" />
          </button>
        </div>
      </div>
    </template>
  </FloatingUi>
</template>

<script setup lang="ts">
const emit = defineEmits(['shown', 'hidden', 'update:isOpen'])

const design = defineProps({
  customDesign: {
    type: String,
    default: ''
  },
  customDesignHead: {
    type: String,
    default: ''
  },
  customDesignFooter: {
    type: String,
    default: ''
  },
  reduceHeight: {
    type: [Number, String],
    default: 100
  }
})

const isOpen: Ref<Boolean> = ref(false)
const floating = ref()

const show = () => {
  floating.value.show()
  isOpen.value = true
  emit('shown')
  emit('update:isOpen', isOpen.value)
}

const hide = (reason?: string) => {
  floating.value.hide(reason)
  isOpen.value = false
  emit('update:isOpen', isOpen.value)
}

const hidden = (reason?: string) => {
  isOpen.value = false
  emit('hidden', reason)
}

const close = () => {
  const { $gtm } = useNuxtApp()
  $gtm.datepickerModule.sendDatepickerCloseEvent()
  hide()
}

const toggle = () => {
  isOpen.value ? hide() : show()
}

defineExpose({
  show,
  hide,
  toggle,
  isOpen
})

</script>
